import React, { useContext, useEffect, useState, useCallback } from 'react';
import { XrplContext } from '../provider/XrplProvider';

function Home() {
  const { _xrpl, client, account, accountInfo } = useContext(XrplContext);
  const [nfts, setNfts] = useState([]);
  const [equippedHeroes, setEquippedHeroes] = useState(Array(16).fill(null));
  const [nftAmount, setNftAmount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const getNfts = useCallback(async () => {
    if (!client || !account) {
      return;
    }
    setIsLoading(true);
    setError('');
    try {
      const { result: { account_nfts } } = await client.request({
        method: "account_nfts",
        account: account
      });
      setNftAmount(account_nfts.length);
      const fetchNfts = account_nfts.map(item => {
        const tokenUri = _xrpl.convertHexToString(item.URI);
        return fetch(tokenUri)
          .then(response => response.json())
          .then(data => ({
            ...data,
            tokenID: item.NFTokenID,
            equipped: false  // Initially mark all as unequipped
          }));
      });
      Promise.all(fetchNfts).then(fullNfts => {
        // First pass to fill equippedHeroes from accountInfo
        const newEquippedHeroes = Array(16).fill(null);
        fullNfts.forEach(nft => {
          if (accountInfo && accountInfo.equippedHeroes.includes(nft.tokenID)) {
            const index = newEquippedHeroes.findIndex(slot => slot === null);
            if (index !== -1) newEquippedHeroes[index] = nft;
          }
        });
  
        // Second pass to update equipped state based on newEquippedHeroes
        const equippedCharacterNames = newEquippedHeroes.filter(hero => hero).map(hero => hero.attributes.find(attr => attr.trait_type === "Character").value);
        const updatedNfts = fullNfts.map(nft => {
          const characterName = nft.attributes.find(attr => attr.trait_type === "Character").value;
          return {
            ...nft,
            equipped: equippedCharacterNames.includes(characterName)
          };
        });
  
        setEquippedHeroes(newEquippedHeroes);
        setNfts(updatedNfts);
      });
    } catch (error) {
      console.error('Failed to fetch NFTs:', error);
      setError('Failed to load NFTs. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [client, account, _xrpl, accountInfo]);
  

  useEffect(() => {
    getNfts();
  }, [getNfts]);

  const equipHero = (index) => {
    const availableIndex = equippedHeroes.findIndex(hero => hero === null);
    if (availableIndex !== -1 && !nfts[index].equipped) {
      const heroToEquip = nfts[index];
      const characterNameToEquip = heroToEquip.attributes?.find(attr => attr.trait_type === "Character")?.value;
      
      const newEquippedHeroes = [...equippedHeroes];
      newEquippedHeroes[availableIndex] = heroToEquip;
      setEquippedHeroes(newEquippedHeroes);

      const updatedNfts = nfts.map(nft => {
        const nftCharacterName = nft.attributes?.find(attr => attr.trait_type === "Character")?.value;
        return nftCharacterName === characterNameToEquip ? { ...nft, equipped: true } : nft;
      });
      setNfts(updatedNfts);
    }
  };

  const removeEquippedHero = (index) => {
    const heroToRemove = equippedHeroes[index];
    const characterNameToRemove = heroToRemove.attributes?.find(attr => attr.trait_type === "Character")?.value;

    setEquippedHeroes(equippedHeroes.map((hero, idx) => idx === index ? null : hero));

    const updatedNfts = nfts.map(nft => {
      const nftCharacterName = nft.attributes?.find(attr => attr.trait_type === "Character")?.value;
      return nftCharacterName === characterNameToRemove ? { ...nft, equipped: false } : nft;
    });
    setNfts(updatedNfts);
  };

  const saveEquippedHeroes = () => {
    equippedHeroes.forEach(hero => {
      if (hero) console.log(`NFTokenID: ${hero.tokenID}`);
    });
    alert("Equipped Heroes Saved!");
  };

  if (!account) {
    return <div className="loading"><center>Connect your wallet</center></div>;
  }

  return (
    <div id="home">
      <div className="title">Equipped Heroes</div>
      <div className="equipped-grid">
        {equippedHeroes.map((hero, index) => (
          <div className="equipped-card" key={index}>
            {hero ? (
              <>
                <img src={hero.image} alt="Equipped Hero" />
                <div className="overlay">
                  <div className="description">{hero.attributes.find(attr => attr.trait_type === "Claim Bonus %")?.value}</div>
                  <div className="character">{hero.attributes.find(attr => attr.trait_type === "Character")?.value}</div>
                </div>
                <button className="remove-btn" onClick={() => removeEquippedHero(index)}>X</button>
              </>
            ) : null}
          </div>
        ))}
      </div>
      <button className="save-btn" onClick={saveEquippedHeroes}>Save</button>
      <div className="title">
        My Heroes ({nftAmount}) <button onClick={getNfts} className="resync-btn">Resync</button>
      </div>
      {isLoading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      <div className="view">
        {nfts.length ? nfts.map((item, index) => (
          <div className={`card ${item.equipped ? 'grey-out' : ''}`} key={index}>
            <img src={item.image} alt="NFT" />
            <div className="overlay">
              <div className="description">{item.attributes.find(attr => attr.trait_type === "Claim Bonus %")?.value}</div>
              <div className="character">{item.attributes.find(attr => attr.trait_type === "Character")?.value}</div>
            </div>
            {!item.equipped && <button className="equip-btn" onClick={() => equipHero(index)}>+</button>}
          </div>
        )) : <div className="no-nft">You don't have any NFTs!</div>}
      </div>
    </div>
  );
}

export default Home;
