import React, { useContext, useEffect, useState } from 'react';
import { XrplContext } from '../provider/XrplProvider';

function Header() {
  const { connectWallet, disconnectWallet, account, accountInfo } = useContext(XrplContext);
  const [isBoxOpened, setIsBoxOpened] = useState(false);
  const [discountValue, setDiscountValue] = useState(0);  // State to manage the discount value

  useEffect(() => {
    // Update discountValue when accountInfo changes
    if (accountInfo && accountInfo.discountValue !== undefined) {
      setDiscountValue(accountInfo.discountValue);
    }
  }, [accountInfo]); // Dependency on accountInfo to update discountValue

  const mint = () => {
    console.log("Minting an NFT!");
  }

  const openBox = () => {
    setIsBoxOpened(true);
  }

  const closeBox = () => {
    setIsBoxOpened(false);
  }

  useEffect(() => {
    closeBox();
  }, [account])

  return (
    <div id="header">
      <div className="container">
        <a href="/">
           <div><h1>Airdrop Heroes</h1></div>
        </a>
        <div className="connect-btn">
          {account ? (
            <>
              <div className="progress-container">
                <div className="progress-title">Active Player Mint Discount</div>
                <div className="progress-bar">
                  <div className="progress-fill" style={{ width: `${discountValue * 100 / 15}%` }}></div>
                  <div className="tooltip">{discountValue}%</div>
                </div>
              </div>
              <button onClick={mint} className="mint-btn">Mint</button> {/* Mint button */}
              <button onClick={openBox}>{account.slice(0, 3) + "..." + account.slice(-3)}</button>
            </>
          ) : (
            <button onClick={connectWallet}>Connect</button>
          )}
        </div>
      </div>
      {isBoxOpened &&
        <div className="msg-box-container">
          <div className="msg-box">
            <div className="top">
              <div className="title">Account Details</div>
              <button onClick={closeBox}>Close</button>
            </div>
            <div className="main">
              {account}
            </div>
            <div className="bottom">
              <button className="disconnect-btn" onClick={disconnectWallet}>Disconnect</button>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Header;
