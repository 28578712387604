import React, { createContext, useEffect, useState } from 'react';
const xrpl = require("xrpl");

const network = "wss://s.devnet.rippletest.net:51233";
const server = "https://mint.airdropgaming.win/php/collection.php";

export const XrplContext = createContext({
    _xrpl: null,
    client: null,
    account: null,
    accountInfo: null,
    connectWallet: () => {},
    disconnectWallet: () => {}
});

function XrplProvider({ children }) {
    const [_xrpl, setXrpl] = useState(null);
    const [client, setClient] = useState(null);
    const [account, setAccount] = useState(localStorage.getItem('account') || null);
    const [accountInfo, setAccountInfo] = useState(null);
    const [url, setUrl] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [qrCode, setQrCode] = useState(null);

    async function connectWallet() {
        fetch(server + '?step=login', {headers: {"Accept": "application/json"}, method: "POST"})
            .then(res => res.json())
            .then(data => {
                setUrl(data.url);
                setQrCode(data.qrCode);
                setIsModalOpen(true);
                let uuid = data.url.split('https://xumm.app/sign/')[1];
                if (uuid) {
                    localStorage.setItem('userId', uuid);
                    pollLogin(uuid);
                }
            });
    }

    async function disconnectWallet() {
        localStorage.removeItem('account');
        localStorage.removeItem('userId');
        setAccount(null);
        setAccountInfo(null);
        setUrl(null);
        setIsModalOpen(false);
    }

    function pollLogin(uuid) {
        let retryCount = 0;
        const interval = setInterval(() => {
            fetch(`${server}?step=verify&uuid=${uuid}`, { method: "POST" })
                .then(res => res.json())
                .then(data => {
                    if (data.signed === 'true' && data.address) {
                        setAccount(data.address);
                        localStorage.setItem('account', data.address);
                        fetchAccountInfo(data.address);
                        clearInterval(interval);
                        setIsModalOpen(false);
                    } else if (retryCount >= 15 || data.signed === 'rejected') {
                        clearInterval(interval);
                        setIsModalOpen(false);
                        alert('Login attempt failed, please try again.');
                    }
                    retryCount++;
                });
        }, 5000);
    }

    function fetchAccountInfo(address) {
        fetch(`${server}?step=accountInfo&account=${address}`, { method: "POST" })
            .then(res => res.json())
            .then(data => {
                setAccountInfo(data);
            });
    }

    useEffect(() => {
        const xrplClient = new xrpl.Client(network);
        xrplClient.connect().then(() => {
            setXrpl(xrpl);
            setClient(xrplClient);
        });

        // Check for existing session
        const storedAccount = localStorage.getItem('account');
        const storedUserId = localStorage.getItem('userId');
        if (storedAccount && storedUserId) {
            setAccount(storedAccount);
            fetchAccountInfo(storedAccount);
        }

        return () => xrplClient.disconnect();
    }, []);

    function Modal({ isOpen, onClose, qrCode }) {
        if (!isOpen) return null;
        return (
            <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)', zIndex: 1000 }}>
                <div style={{ padding: '20px', background: '#fff', borderRadius: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h2>Connect Wallet</h2>
                    <img src={qrCode} alt="Scan QR Code" />
                    <p>Scan the QR code with your Xumm or click the button below to authorize.</p>
                    <button onClick={() => window.open(url, '_blank')} style={{ marginTop: '10px' }}>Open Xaman</button>
                    <button onClick={onClose} style={{ marginTop: '20px', cursor: 'pointer' }}>Close</button>
                </div>
            </div>
        );
    }

    return (
        <XrplContext.Provider value={{ _xrpl, client, account, accountInfo, connectWallet, disconnectWallet }}>
            {children}
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} qrCode={qrCode} />
        </XrplContext.Provider>
    );
}

export default XrplProvider;
